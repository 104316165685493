// material-ui
import { useTheme, styled } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { IconCircleX, IconX } from '@tabler/icons'
import moment from 'moment'
import useAuth from 'hooks/useAuth'
import { serverOffset } from 'store/constant'
import useLang from 'hooks/useLang'

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  // cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}))

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({
  notifications,
  readNotification,
  discardNotification
}) => {
  const theme = useTheme()
  const { lang } = useLang()
  const { hasPermission } = useAuth()
  const chipSX = {
    height: 24,
    padding: '0 6px'
  }
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.orange.light,
    marginRight: '5px'
    // cursor: 'pointer'
  }

  const chipDangerSX = {
    ...chipSX,
    color: theme.palette.error.dark,
    backgroundColor: 'transparent',
    cursor: 'pointer'
  }

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.success.light
    // height: 28
  }

  return (
    <List
      sx={{
        width: '100%',
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 0
        }
      }}
    >
      {notifications.map(n => {
        return (
          <>
            <Grid
              sx={{
                padding: '15px',
                position: 'relative',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.secondary.dark
                      : theme.palette.secondary.light
                }
              }}
              container
            >
              <Grid
                sm={11}
                sx={{ cursor: 'pointer' }}
                onClick={() => readNotification(n, true)}
              >
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems={'center'}
                >
                  <ListItemText
                    primary={
                      n.reference == 'booking'
                        ? n.trigger == 'creation'
                          ? lang == 'Arabic'
                            ? 'حجز جديد'
                            : 'New booking'
                          : lang == 'Arabic'
                          ? 'إلغاء حجز'
                          : 'Booking cancellation'
                        : n.reference == 'booked unit'
                        ? n.trigger == 'creation'
                          ? lang == 'Arabic'
                            ? 'حجز جديد'
                            : 'New booking'
                          : lang == 'Arabic'
                          ? 'إلغاء حجز'
                          : 'Booking cancellation'
                        : ''
                    }
                  />
                  <Grid item>
                    <Typography variant='caption' display='block'>
                      {moment(n?.created_at).utcOffset(serverOffset).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='column' className='list-container'>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2'>{n.message}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* //check is MERCHANT or has PERMISSION */}
              {hasPermission('notifications', 'write') && (
                <Grid
                  sm={1}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginTop={'30px'}
                  marginBottom={'15px'}
                >
                  <IconX
                    size={15}
                    color={theme.palette.grey.main}
                    onClick={() => discardNotification(n)}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
              )}
              {!n.read_at && (
                <Badge
                  variant='dot'
                  color='error'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  style={{
                    position: 'absolute',
                    top: '30px',
                    ...(lang == 'Arabic'
                      ? { left: '26.5px' }
                      : { right: '26.5px' })
                  }}
                ></Badge>
              )}
              <Grid
                container
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {!n.read_at && (
                  <>
                    <Grid item sx={{ pt: 0 }}>
                      <Typography
                        variant='subtitle2'
                        color='primary'
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                        onClick={() => readNotification(n, false)}
                      >
                        {lang == 'Arabic' ? 'تم القراءة' : 'Mark as read'}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Divider />
          </>
        )
      })}
    </List>
  )
}

export default NotificationList
