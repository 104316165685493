import { useEffect, useState } from 'react'

// material-ui
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { IconCircleX, IconDatabase, IconPlus } from '@tabler/icons'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { capitalize } from 'store/actions/bookingActions'
import humanizeDuration from 'humanize-duration'
import { serverOffset } from 'store/constant'
import useLang from 'hooks/useLang'
import { statusEngAr } from 'store/actions/helperFns'

// ==============================|| TABLE - STICKY HEADER ||============================== //

export default function SimpleBookingTable (props) {
  const { rows, columns, bookedBy, paidAmount } = props
  const [totalsBeforeDiscount, setTotalsBeforeDiscount] = useState(0)
  const [totalsAfterDiscount, setTotalsAfterDiscount] = useState(0)
  const [discounts, setDiscounts] = useState(0)
  const theme = useTheme()
  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )
  const { lang } = useLang()
  useEffect(() => {
    let totalRows = 0
    let totalDiscounts = 0
    rows.map(row => {
      const optionsPrice = row?.booking_options?.reduce(
        (a, b) => +a + +b.price,
        0
      )
      if (row?.canceled_by || row?.operation == 'cancel') {
        return
      }

      totalRows += parseFloat(row.price)
      if (optionsPrice > 0) {
        totalRows += parseFloat(optionsPrice)
      }
      totalDiscounts += row?.discount_percent
        ? (parseFloat(row.price) + parseFloat(optionsPrice)) *
          parseFloat(row.discount_percent)
        : 0
    })

    setTotalsBeforeDiscount(totalRows)
    setDiscounts(totalDiscounts)
    setTotalsAfterDiscount(totalRows - totalDiscounts)
  }, [rows])

  return (
    <MainCard content={false} title={props?.title} secondary={<></>}>
      {/* table */}
      <TableContainer>
        <Table sx={{ minWidth: 350 }} aria-label={props?.title}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  //   sx={{ py: 3 }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align='center' sx={{ pr: 3 }}>
                {props?.plain ? (lang == 'Arabic' ? 'الحالة' : 'Status') : ''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              const now = moment()
                .utcOffset(serverOffset)
                .format('YYYY-DD-MM HH:mm:ss')
              const end = moment(row?.ending_date_time).format(
                'YYYY-DD-MM HH:mm:ss'
              )

              const highlight =
                props?.selectedUUID &&
                row?.booked_unit_uuid == props?.selectedUUID
              const optionsPrice = row?.booking_options?.reduce(
                (a, b) => +a + +b.price,
                0
              )
              let rowTotalBeforeDiscount = parseFloat(row.price)
              if (optionsPrice > 0) {
                rowTotalBeforeDiscount += parseFloat(optionsPrice)
              }
              const discount = row?.discount_percent
                ? parseFloat(row.discount_percent) * rowTotalBeforeDiscount
                : 0
              const total = rowTotalBeforeDiscount - discount
              return (
                <>
                  <TableRow
                    hover
                    key={row.code}
                    sx={
                      highlight
                        ? {
                            backgroundColor: 'rgba(128,128,128,.1)'
                          }
                        : {}
                    }
                  >
                    {columns.map(column => {
                      let value = row[column.id]

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id == 'items' ? (
                            <Grid>
                              <div fontWeight={'bold'}>
                                {moment(row?.booked_date).format(
                                  'MMMM DD, YYYY'
                                )}{' '}
                                {lang == 'Arabic' ? 'الساعة' : 'at'}{' '}
                                {moment(
                                  row?.booked_date + ' ' + row.booked_time
                                ).format('hh:mm A')}
                                <div fontWeight={'bold'}>
                                  (
                                  {humanizeDuration(
                                    row.service_interval_time * 1000 * 60,
                                    lang == 'Arabic' ? 'ar' : 'en'
                                  )}
                                  )
                                </div>
                              </div>
                              <div>
                                {bookingSchemes.sc_quantity && (
                                  <Typography>
                                    {row.quantity +
                                      ' ' +
                                      (row?.quantity > 1
                                        ? bookingSchemes.quantity_pl
                                        : bookingSchemes.quantity_si)}
                                  </Typography>
                                )}
                                <Typography fontWeight={'bold'}>
                                  {row.service_name}
                                </Typography>
                                {' - '}
                                {lang == 'Arabic'
                                  ? row.price + ' ' + 'ر.س'
                                  : 'SAR' + ' ' + row.price}
                              </div>
                              {row?.booking_options?.length > 0 && (
                                <div>
                                  {lang == 'Arabic' ? 'الخيارات:' : 'Options:'}{' '}
                                  {row?.booking_options?.map((opt, index) => (
                                    <span>
                                      {opt.name} ({opt.quantity}) SAR{' '}
                                      {opt.price}
                                      {index == row?.booking_options?.length - 1
                                        ? ' '
                                        : ', '}
                                    </span>
                                  ))}
                                </div>
                              )}
                              <div style={{ marginBottom: '10px' }}>
                                <Typography fontWeight={'bold'}>
                                  {row.resource_name}
                                </Typography>
                              </div>

                              {parseFloat(row.discount_percent) > 0 && (
                                <div
                                  style={{ color: theme.palette.error.light }}
                                >
                                  {(
                                    parseFloat(row.discount_percent) * 100
                                  ).toFixed()}
                                  {lang == 'Arabic' ? '% خصم' : '% OFF'}{' '}
                                  {row?.discount_code
                                    ? '(' +
                                      (lang == 'Arabic'
                                        ? 'كود الخصم:'
                                        : 'Discount code:') +
                                      ' ' +
                                      row.discount_code +
                                      ')'
                                    : ''}
                                </div>
                              )}
                              {props.plain && (
                                <>
                                  <div>
                                    {lang == 'Arabic'
                                      ? 'تم إنشاؤه في'
                                      : 'Created on'}{' '}
                                    {moment(row?.created_at).format(
                                      'MMMM DD, YYYY'
                                    )}{' '}
                                    {lang == 'Arabic' ? 'الساعة' : 'at'}{' '}
                                    {moment(row?.created_at).format('hh:mm A')}
                                  </div>
                                  {row.created_at != row.updated_at && (
                                    <div>
                                      {lang == 'Arabic'
                                        ? 'تم تحديثه في'
                                        : 'Updated on'}{' '}
                                      {moment(row?.updated_at).format(
                                        'MMMM DD, YYYY'
                                      )}{' '}
                                      {lang == 'Arabic' ? 'الساعة' : 'at'}{' '}
                                      {moment(row?.updated_at).format(
                                        'hh:mm A'
                                      )}
                                    </div>
                                  )}
                                  {/* <div style={{ marginTop: 5 }}>
                                    {capitalize(bookingSchemes.booked_unit_si)}{' '}
                                    UUID: {row.booked_unit_uuid}
                                  </div> */}
                                </>
                              )}
                            </Grid>
                          ) : column.id == 'price' ? (
                            <>
                              {lang == 'Arabic'
                                ? parseFloat(total).toFixed(2) + ' ' + 'ر.س'
                                : 'SAR' + ' ' + parseFloat(total).toFixed(2)}

                              {parseFloat(row.discount_percent) > 0 && (
                                <Typography
                                  style={{
                                    textDecoration: 'line-through',
                                    fontSize: '0.7em',
                                    color: theme.palette.grey.light
                                  }}
                                >
                                  {lang == 'Arabic'
                                    ? parseFloat(
                                        rowTotalBeforeDiscount
                                      ).toFixed(2) +
                                      ' ' +
                                      'ر.س'
                                    : 'SAR' +
                                      ' ' +
                                      parseFloat(
                                        rowTotalBeforeDiscount
                                      ).toFixed(2)}{' '}
                                  {}
                                </Typography>
                              )}
                            </>
                          ) : (
                            value
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell
                      align='center'
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      {props.plain && (
                        <Stack
                          direction='row'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Typography
                            color={
                              row['status'] == 'canceled'
                                ? theme.palette.orange.dark
                                : row['status'] == 'no show'
                                ? theme.palette.orange.dark
                                : row['status'] == 'checked in'
                                ? theme.palette.success.dark
                                : row['status'] == 'checked out'
                                ? theme.palette.grey[700]
                                : row['status'] == 'in service'
                                ? theme.palette.success.dark
                                : theme.palette.mode == 'dark'
                                ? theme.palette.common.dark
                                : theme.palette.primary.dark
                            }
                          >
                            {row.canceled_by
                              ? lang == 'Arabic'
                                ? 'ملغي من قبل '
                                : 'Canceled by ' +
                                  row.canceled_by +
                                  (lang == 'Arabic' ? ' في ' : ' on ') +
                                  row.updated_at
                              : statusEngAr(capitalize(row['status']), lang)}
                          </Typography>
                        </Stack>
                      )}
                      {!props.plain && (
                        <Stack
                          direction='row'
                          justifyContent='center'
                          alignItems='center'
                        >
                          {!row?.canceled_by &&
                            (moment(row?.utc_ending_date_time) <
                              moment().add(3, 'seconds') &&
                            row?.operation == 'cancel' ? (
                              <>
                                <Typography color={theme.palette.error.main}>
                                  {lang == 'Arabic'
                                    ? 'الإلغاء لم يعد ممكنا'
                                    : 'Cancellation is no longer possible'}
                                </Typography>
                              </>
                            ) : (
                              <Grid>
                                {(row.status == 'updated' ||
                                  row.status == 'new') &&
                                  moment(end) <
                                    moment(now).add(3, 'seconds') && (
                                    <Grid sm={12}>
                                      <Typography
                                        color={theme.palette.error.main}
                                      >
                                        {lang == 'Arabic'
                                          ? 'غير صالح، انتهى'
                                          : 'Invalid, ended'}{' '}
                                        {moment(end).from(moment(now))}
                                      </Typography>
                                    </Grid>
                                  )}
                                {row?.operation == 'cancel' &&
                                  moment(row?.old_ending_date_time) >
                                    moment().add(3, 'seconds') && (
                                    <Grid sm={12}>
                                      <Typography>
                                        {' '}
                                        {lang == 'Arabic'
                                          ? 'تم ضبطه للإلغاء'
                                          : 'Set to cancel'}
                                      </Typography>
                                    </Grid>
                                  )}
                                <Grid
                                  sm={12}
                                  display={'flex'}
                                  justifyContent='center'
                                >
                                  {(bookedBy == 'merchant' ||
                                    row?.status == 'new') && (
                                    <Button
                                      startIcon={<EditOutlinedIcon />}
                                      onClick={() => props.onEdit(row)}
                                    >
                                      {lang == 'Arabic' ? 'تعديل' : 'Modify'}
                                    </Button>
                                  )}
                                  {(row?.status == 'new' ||
                                    (row?.operation != 'cancel' &&
                                      moment(row?.old_ending_date_time) >
                                        moment().add(3, 'seconds'))) && (
                                    <Button
                                      startIcon={
                                        row?.status == 'new' ? (
                                          <DeleteOutlineOutlinedIcon />
                                        ) : (
                                          <IconCircleX />
                                        )
                                      }
                                      onClick={() => props.onDelete(row)}
                                    >
                                      {row?.status == 'new'
                                        ? lang == 'Arabic'
                                          ? 'إزالة'
                                          : 'Remove'
                                        : lang == 'Arabic'
                                        ? 'إلفاء'
                                        : 'Cancel'}
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                          {row?.canceled_by && (
                            <>
                              <Typography>
                                {lang == 'Arabic'
                                  ? 'ملغي من قبل'
                                  : 'Canceled by'}{' '}
                                {row?.canceled_by}
                                (lang == 'Arabic' ? ' في ' : ' on ')
                                {row?.updated_at}
                              </Typography>
                            </>
                          )}
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              )
            })}

            {parseFloat(discounts) > 0 && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography>
                      {lang == 'Arabic'
                        ? 'المجموع قبل الخصم'
                        : 'Total before discount'}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <div>
                      {' '}
                      <Typography>
                        {lang == 'Arabic'
                          ? parseFloat(totalsBeforeDiscount).toFixed(2) +
                            ' ' +
                            'ر.س'
                          : 'SAR' +
                            ' ' +
                            parseFloat(totalsBeforeDiscount).toFixed(2)}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography>
                      {lang == 'Arabic' ? 'الخصم' : 'Discount'}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <div>
                      {' '}
                      <Typography>
                        {lang == 'Arabic'
                          ? parseFloat(discounts).toFixed(2) + ' ' + 'ر.س'
                          : 'SAR' + ' ' + parseFloat(discounts).toFixed(2)}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow>
              <TableCell>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'الإجمالي' : 'Total'}
                </Typography>
              </TableCell>
              <TableCell colSpan={2}>
                <div>
                  {' '}
                  <Typography fontWeight={'bold'}>
                    {lang == 'Arabic'
                      ? parseFloat(totalsAfterDiscount).toFixed(2) + ' ' + 'ر.س'
                      : 'SAR' +
                        ' ' +
                        parseFloat(totalsAfterDiscount).toFixed(2)}
                  </Typography>
                </div>
                <div>
                  (
                  {lang == 'Arabic'
                    ? `يشمل 15% ضريبة القيمة المضافة تعادل ${parseFloat(
                        totalsAfterDiscount - totalsAfterDiscount / 1.15
                      ).toFixed(2)} ر.س`
                    : 'Includes 15% VAT of SAR ' +
                      parseFloat(
                        totalsAfterDiscount - totalsAfterDiscount / 1.15
                      ).toFixed(2)}
                  )
                </div>
              </TableCell>
            </TableRow>
            {paidAmount > 0 && (
              <TableRow>
                <TableCell>
                  <Typography
                    fontWeight={'bold'}
                    color={theme.palette.success.main}
                  >
                    {lang == 'Arabic' ? 'المبلغ المدفوع' : 'Paid amount'}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <div>
                    {' '}
                    <Typography
                      fontWeight={'bold'}
                      color={theme.palette.success.main}
                    >
                      {lang == 'Arabic'
                        ? parseFloat(paidAmount).toFixed(2) + ' ' + 'ر.س'
                        : 'SAR' + ' ' + parseFloat(paidAmount).toFixed(2)}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* table pagination */}
    </MainCard>
  )
}
