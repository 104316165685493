import { useState, useRef, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography
} from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'
import UpgradePlanCard from './UpgradePlanCard'
import useAuth from 'hooks/useAuth'
import User1 from 'assets/images/users/user-round.svg'

// assets
import {
  IconFileAlert,
  IconFileInfo,
  IconLogout,
  IconSearch,
  IconSettings,
  IconUser
} from '@tabler/icons'
import TermsDialog from 'ui-component/modals/TermsDialog'
import useLang from 'hooks/useLang'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme()
  const { lang } = useLang()
  const customization = useSelector(state => state.customization)
  const navigate = useNavigate()

  const location = useLocation()

  const [sdm, setSdm] = useState(true)
  const [value, setValue] = useState('')
  const [notification, setNotification] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const { logout, user } = useAuth()
  const [open, setOpen] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null)
  const handleLogout = async () => {
    try {
      await logout()
    } catch (err) {
      console.error(err)
    }
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index)
    handleClose(event)

    if (route && route !== '') {
      navigate(route)
    }
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      {openTerms && <TermsDialog onClose={() => setOpenTerms(prev => !prev)} />}

      <IconButton
        color='inherit'
        size='large'
        disableRipple
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        // color='primary'
      >
        <Avatar
          src={user?.image}
          sx={{
            ...theme.typography.mediumAvatar,
            margin: '0px !important',
            cursor: 'pointer'
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          color='inherit'
        />
      </IconButton>

      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Typography variant='h4'>
                          {lang == 'Arabic' ? 'أهلا' : 'Hi,'}
                        </Typography>
                        <Typography
                          component='span'
                          variant='h4'
                          sx={{ fontWeight: 400 }}
                        >
                          {user?.manager
                            ? user.manager.split(' ')[0]
                            : user?.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  {/* <PerfectScrollbar
                    style={{
                      height: '100%',
                      maxHeight: 'calc(100vh - 250px)',
                      overflowX: 'hidden'
                    }}
                  > */}
                  <Box sx={{ p: 2, pt: 0 }}>
                    {/* <UpgradePlanCard /> */}

                    <Divider />
                    <List
                      component='nav'
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`
                        }}
                        selected={
                          selectedIndex === 0 && location.pathname == '/account'
                        }
                        onClick={event =>
                          handleListItemClick(event, 0, '/account')
                        }
                      >
                        <ListItemIcon>
                          <IconSettings stroke={1.5} size='1.3rem' />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body2'>
                              {lang == 'Arabic' ? 'الحساب' : 'Account'}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`
                        }}
                        selected={selectedIndex === 1}
                        onClick={event => setOpenTerms(true)}
                      >
                        <ListItemIcon>
                          <IconFileAlert stroke={1.5} size='1.3rem' />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body2'>
                              {lang == 'Arabic' ? 'الأحكام' : 'Terms'}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      {/* <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`
                          }}
                          selected={selectedIndex === 1}
                          onClick={event =>
                            handleListItemClick(
                              event,
                              1,
                              '/user/social-profile/posts'
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size='1.3rem' />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent='space-between'
                              >
                                <Grid item>
                                  <Typography variant='body2'>
                                    Social Profile
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    label='02'
                                    size='small'
                                    sx={{
                                      bgcolor:
                                        theme.palette.mode === 'dark'
                                          ? theme.palette.dark.dark
                                          : theme.palette.warning.dark,
                                      color: theme.palette.background.default
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton> */}
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`
                        }}
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size='1.3rem' />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body2'>
                              {lang == 'Arabic' ? 'تسجيل الخروج' : 'Logout'}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default ProfileSection
