// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
  IconFileUpload,
  IconPictureInPicture,
  IconSettings
} from '@tabler/icons'

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const extras = {
  id: 'extras-menu',
  type: 'group',
  children: [
    {
      id: 'submissions',
      title: 'Submissions',
      title_ar: 'التقديمات',
      type: 'item',
      url: '/submissions',
      icon: IconPictureInPicture,
      breadcrumbs: false
    }
  ]
}

export default extras
