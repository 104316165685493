import ReactDOM from 'react-dom'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// load mock apis
import '_mockApis'

// project imports
import * as serviceWorker from 'serviceWorker'
import App from 'App'
import { store, persister } from 'store'

// style + assets
import 'assets/scss/style.scss'
import 'leaflet/dist/leaflet.css'
import config from 'config'
import { LanguageProvider } from 'contexts/LanguageContext'

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={config.basename}>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
