import * as actionTypes from '../actions'

const defaultScheme = {
  resource_si: 'resource',
  resource_pl: 'resources',
  resource_group_si: 'group',
  resource_group_pl: 'groups',
  service_si: 'service',
  service_pl: 'services',
  service_group_si: 'group',
  service_group_pl: 'groups',
  service_time_si: 'service duration',
  service_time_pl: 'service durations',

  booking_si: 'booking',
  booking_pl: 'bookings',

  booked_unit_si: 'booked unit',
  booked_unit_pl: 'booked units',
  quantity_si: 'quantity',
  quantity_pl: 'quantities',
  unit_si: 'unit',
  unit_pl: 'units',
  info_si: 'info', // 404
  info_pl: 'info', // 404

  resource_si_ar: 'المحجوز',
  resource_pl_ar: 'المحجوزات',
  resource_group_si_ar: 'المجموعة',
  resource_group_pl_ar: 'المجموعات',
  service_si_ar: 'الخدمة',
  service_pl_ar: 'الخدمات',
  service_group_si_ar: 'المجموعة',
  service_group_pl_ar: 'المجموعات',
  service_time_si_ar: 'مدة الخدمة',
  service_time_pl_ar: 'مدد الخدمات',
  booking_si_ar: 'الحجز',
  booking_pl_ar: 'الحجوزات',
  booked_unit_si_ar: 'الوحدة المحجوزة',
  booked_unit_pl_ar: 'الوحدات المحجوزة',
  quantity_si_ar: 'العدد',
  quantity_pl_ar: 'الأعداد',
  unit_si_ar: 'الوحدة',
  unit_pl_ar: 'الوحدات',
  info_si_ar: 'المعلومات',
  info_pl_ar: 'المعلومات'
}
const initialState = {
  fetchedSchemes: false,
  bookingResources: [],
  bookingServices: [],
  bookingSlots: [],
  noBookingSlots: [],
  bookings: [],
  totalBookings: 0,
  bookingDiscounts: [],
  bookingSchemes: defaultScheme,
  bookedUnits: []
}

export const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_RESOURCES:
      return { ...state, bookingResources: action.bookingResources }
    case actionTypes.GET_BOOKING_SERVICES:
      return { ...state, bookingServices: action.bookingServices }
    case actionTypes.GET_BOOKING_SLOTS:
      return { ...state, bookingSlots: action.bookingSlots }
    case actionTypes.GET_NOBOOKING_SLOTS:
      return { ...state, noBookingSlots: action.noBookingSlots }
    case actionTypes.GET_BOOKING_DISCOUNTS:
      return { ...state, bookingDiscounts: action.bookingDiscounts }
    case actionTypes.GET_BOOKED_UNITS:
      return { ...state, bookedUnits: action.bookedUnits }
    case actionTypes.GET_BOOKING_SCHEME:
      return {
        ...state,
        fetchedSchemes: true,
        bookingSchemes: action.bookingScheme
          ? { ...defaultScheme, ...action.bookingScheme }
          : defaultScheme
      }
    case actionTypes.GET_BOOKINGS:
      return {
        ...state,
        bookings: action.bookings,
        totalBookings: action.total
      }
    default:
      return state
  }
}
