import { Button, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import SimpleBookingTable from 'ui-component/tables/SimpleBookingTable'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useSelector } from 'react-redux'
import { capitalize } from 'store/actions/bookingActions'
import SubCard from 'ui-component/cards/SubCard'
import { useTheme } from '@mui/material/styles'
import { gridSpacing } from 'store/constant'
import useAuth from 'hooks/useAuth'
import useLang from 'hooks/useLang'

const editPermission = 'bookings'
const editPermissionVal = 'write'

const BookingView = ({
  selectedUUID,
  booking,
  summaryColumns,
  onEdit,
  canEdit
}) => {
  const { lang } = useLang()
  const { hasPermission } = useAuth()
  const theme = useTheme()

  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )
  return (
    <>
      {booking?.booked_units?.length > 0 && (
        <>
          <Grid item xs={12} py={3}>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems='center'
              style={{
                marginBottom: '10px'
              }}
            >
              <Typography display={'flex'}>
                <Typography fontWeight={'bold'} marginRight={'10px'}>
                  {' '}
                  {lang == 'Arabic' ? 'محجوز من قبل' : 'Booked by'}
                </Typography>{' '}
                {lang == 'Arabic'
                  ? booking.booked_by == 'user'
                    ? 'المستخدم'
                    : 'التاجر'
                  : capitalize(booking?.booked_by)}
              </Typography>
              {/* check is MERCHANT or has PERMISSION */}
              {hasPermission(editPermission, editPermissionVal) && canEdit && (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  variant='text'
                  onClick={() => {
                    onEdit()
                  }}
                >
                  {lang == 'Arabic' ? 'تعديل' : 'Edit'}
                </Button>
              )}
            </Grid>

            <SimpleBookingTable
              bookedBy={booking?.booked_by}
              paidAmount={booking?.paid_amount}
              plain
              selectedUUID={selectedUUID}
              rows={booking?.booked_units}
              columns={summaryColumns}
              title={
                lang == 'Arabic'
                  ? 'ملخص الحجز'
                  : 'Booking summary' +
                    (booking?.booked_units?.length > 1
                      ? ' (' + booking?.booked_units?.length + ')'
                      : '')
              }
            />
          </Grid>
          <SubCard display='flex'>
            <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'الإسم' : 'Name'}
                </Typography>
                <Typography>
                  {booking.first_name + ' ' + booking.last_name || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'الهاتف' : 'Phone'}
                </Typography>
                <Typography>{booking.phone ?? ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'الملاحظات' : 'Notes'}
                </Typography>
                <Typography>{booking.notes || ''}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'تم إنشاؤه في' : 'Created on'}
                </Typography>
                <Typography>
                  {booking.created_at
                    ? moment(booking.created_at).format('MMMM DD, YYYY') +
                      ' ' +
                      (lang == 'Arabic' ? 'الساعة' : 'at') +
                      ' ' +
                      moment(booking.created_at).format('hh:mm A')
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={'bold'}>
                  {lang == 'Arabic' ? 'تم تحديثه في' : 'Updated on'}
                </Typography>
                <Typography>
                  {booking.updated_at
                    ? moment(booking.updated_at).format('MMMM DD, YYYY') +
                      ' ' +
                      (lang == 'Arabic' ? 'الساعة' : 'at') +
                      ' ' +
                      moment(booking.updated_at).format('hh:mm A')
                    : ''}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Typography fontWeight={'bold'}>
                  {capitalize(bookingSchemes.booking_si)} UUID
                </Typography>
                <Typography>{booking.booking_uuid}</Typography>
              </Grid> */}
            </Grid>
          </SubCard>
        </>
      )}
    </>
  )
}

export default BookingView
