import { useSelector } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider, Typography } from '@mui/material'

// routing
import Routes from 'routes'

// defaultTheme
import themes from 'themes'

// project imports
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import RTLLayout from 'ui-component/RTLLayout'
import Snackbar from 'ui-component/extended/Snackbar'

// auth provider
// import { FirebaseProvider } from 'contexts/FirebaseContext';
import { AuthProvider } from 'contexts/AuthContext'
import { useEffect, useState } from 'react'
import TermsDialog from 'ui-component/modals/TermsDialog'
import { InfoDialog } from 'ui-component/modals/InfoDialog'
import { networkError } from 'utils/axios'
import useLang from 'hooks/useLang'
// import { JWTProvider }from 'contexts/JWTContext';
// import { Auth0Provider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
  const { lang } = useLang()
  const customization = useSelector(state => state.customization)
  const [networkOpen, setNetworkInfoOpen] = useState(!navigator.onLine)

  useEffect(() => {
    const axiosErrorCheck = setInterval(() => {
      if (networkError) {
        setNetworkInfoOpen(true)
      }
    }, 1000 * 3)

    const handleStatusChange = e => {
      setNetworkInfoOpen(e.type == 'offline')
    }

    window.addEventListener('online', handleStatusChange)

    window.addEventListener('offline', handleStatusChange)

    return () => {
      clearInterval(axiosErrorCheck)
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        {/* RTL layout */}
        <RTLLayout>
          <Locales>
            <NavigationScroll>
              <AuthProvider>
                <>
                  <InfoDialog
                    title={
                      lang == 'Arabic'
                        ? 'لا يوجد اتصال بالشبكة'
                        : 'No network connection'
                    }
                    bnText={lang == 'Arabic' ? 'حسنا' : 'Okay'}
                    open={networkOpen}
                    onClose={() => {
                      setNetworkInfoOpen(false)
                    }}
                  >
                    <Typography>
                      {lang == 'Arabic'
                        ? 'يرجى الاتصال بالإنترنت'
                        : 'Please connect to the internet'}
                    </Typography>
                  </InfoDialog>
                  <Routes />
                  <Snackbar />
                </>
              </AuthProvider>
            </NavigationScroll>
          </Locales>
        </RTLLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
