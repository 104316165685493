import { Button, Menu, MenuItem } from '@mui/material'
import useLang from 'hooks/useLang'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const CustomPeriod = ({ onPeriodChange, startDate, endDate }) => {
  const { lang } = useLang()
  const [anchorEl, setAnchorEl] = useState(null)
  const [customPeriods, setcustomPeriods] = useState([
    {
      title: lang == 'Arabic' ? 'اليوم' : 'Today',
      start: moment().format('yyyy-MM-DD'),
      end: moment().format('yyyy-MM-DD')
    },
    {
      title: lang == 'Arabic' ? 'الأمس' : 'Yesterday',
      start: moment().subtract(1, 'day').format('yyyy-MM-DD'),
      end: moment().subtract(1, 'day').format('yyyy-MM-DD')
    },
    {
      title: lang == 'Arabic' ? 'هذا الأسبوع' : 'This week',
      start: moment().startOf('week').format('yyyy-MM-DD'),
      end: moment().endOf('week').format('yyyy-MM-DD')
    },
    {
      title: lang == 'Arabic' ? 'الأسبوع الماضي' : 'Last week',
      start: moment().startOf('week').subtract(1, 'week').format('yyyy-MM-DD'),
      end: moment().endOf('week').subtract(1, 'week').format('yyyy-MM-DD')
    },

    {
      title: lang == 'Arabic' ? 'آخر 7 أيام' : 'Last 7 days',
      start: moment().subtract(8, 'days').format('yyyy-MM-DD'),
      end: moment().subtract(1, 'day').format('yyyy-MM-DD')
    },

    {
      title: lang == 'Arabic' ? 'هذا الشهر' : 'This month',
      start: moment().startOf('month').format('yyyy-MM-DD'),
      end: moment().endOf('month').format('yyyy-MM-DD')
    },
    {
      title: lang == 'Arabic' ? 'الشهر الماضي' : 'Last month',
      start: moment()
        .startOf('month')
        .subtract(1, 'month')
        .format('yyyy-MM-DD'),
      end: moment().endOf('month').subtract(1, 'month').format('yyyy-MM-DD')
    },
    {
      title: lang == 'Arabic' ? 'آخر 30 يوم' : 'Last 30 days',
      start: moment().subtract(31, 'days').format('yyyy-MM-DD'),
      end: moment().subtract(1, 'day').format('yyyy-MM-DD')
    }
  ])

  const [selectedPeriod, setSelectedPeriod] = useState()

  useEffect(() => {
    setcustomPeriods([
      {
        title: lang == 'Arabic' ? 'اليوم' : 'Today',
        start: moment().format('yyyy-MM-DD'),
        end: moment().format('yyyy-MM-DD')
      },
      {
        title: lang == 'Arabic' ? 'الأمس' : 'Yesterday',
        start: moment().subtract(1, 'day').format('yyyy-MM-DD'),
        end: moment().subtract(1, 'day').format('yyyy-MM-DD')
      },
      {
        title: lang == 'Arabic' ? 'هذا الأسبوع' : 'This week',
        start: moment().startOf('week').format('yyyy-MM-DD'),
        end: moment().endOf('week').format('yyyy-MM-DD')
      },
      {
        title: lang == 'Arabic' ? 'الأسبوع الماضي' : 'Last week',
        start: moment()
          .startOf('week')
          .subtract(1, 'week')
          .format('yyyy-MM-DD'),
        end: moment().endOf('week').subtract(1, 'week').format('yyyy-MM-DD')
      },

      {
        title: lang == 'Arabic' ? 'آخر 7 أيام' : 'Last 7 days',
        start: moment().subtract(8, 'days').format('yyyy-MM-DD'),
        end: moment().subtract(1, 'day').format('yyyy-MM-DD')
      },

      {
        title: lang == 'Arabic' ? 'هذا الشهر' : 'This month',
        start: moment().startOf('month').format('yyyy-MM-DD'),
        end: moment().endOf('month').format('yyyy-MM-DD')
      },
      {
        title: lang == 'Arabic' ? 'الشهر الماضي' : 'Last month',
        start: moment()
          .startOf('month')
          .subtract(1, 'month')
          .format('yyyy-MM-DD'),
        end: moment().endOf('month').subtract(1, 'month').format('yyyy-MM-DD')
      },
      {
        title: lang == 'Arabic' ? 'آخر 30 يوم' : 'Last 30 days',
        start: moment().subtract(31, 'days').format('yyyy-MM-DD'),
        end: moment().subtract(1, 'day').format('yyyy-MM-DD')
      }
    ])
  }, [lang])

  useEffect(() => {
    const selected = customPeriods.find(
      f => f.start == startDate && f.end == endDate
    )
    setSelectedPeriod(
      selected?.title ?? (lang == 'Arabic' ? 'فترة مخصصة' : 'Custom period')
    )
  }, [startDate, endDate, customPeriods])

  const handleClick = event => {
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        onClick={e => {
          e.stopPropagation()
          handleClick(e)
        }}
        aria-controls='custom-period'
        aria-haspopup='true'
      >
        {selectedPeriod}
      </Button>
      <Menu
        id='custom-period'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant='selectedMenu'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {customPeriods.map(filter => (
          <MenuItem
            style={
              {
                // color: unit.status == st.status ? theme.palette.primary.main : '',
                // fontWeight: unit.status == st.status ? 'bold' : 'normal'
              }
            }
            onClick={() => {
              onPeriodChange(filter)
              handleClose()
            }}
          >
            {filter.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CustomPeriod
