import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

import axios, { globalRetriesCount } from 'utils/axios'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'
import NotificationList from './NotificationList'

// assets
import { IconBell } from '@tabler/icons'
import { useDispatch, useSelector } from 'react-redux'
import API from 'utils/constants/API'
import { useNavigate } from 'react-router-dom'
import { debounceFunction, throttleFunction } from 'store/actions/helperFns'
import { soundFiles } from 'store/constant'

import { updateNotificationCount } from 'store/actions/listingActions'
import useLang from 'hooks/useLang'

// ==============================|| NOTIFICATION ||============================== //
let calculated = -1
const NotificationSection = () => {
  const { lang } = useLang()
  const audio = new Audio()
  audio.loop = false
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('false')
  const [tab, setTab] = useState(0)
  const [payload, setPayload] = useState({})
  const [notifications, setNotifications] = useState([
    // {
    //   message: 'test',
    //   created_at: '2022-08-10',
    //   type: 'booking',
    //   trigger: 'creation'
    // }
  ])
  const [accountCount, setAccountCount] = useState(-1)
  const [listingCount, setListingCount] = useState(-1)
  const [counter, setCounter] = useState(0)
  const dispatch = useDispatch()
  const listing = useSelector(
    ({ listingsStore }) => listingsStore.selectedListing
  )

  const tabsOption = [
    {
      label: lang == 'Arabic' ? 'الإدراج' : 'Listing'
      //   icon: <LockTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
      label: lang == 'Arabic' ? 'الحساب' : 'Account'
      //   icon: <LockTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    }
  ]

  // notification status options
  const status = [
    {
      value: 'false',
      label: lang == 'Arabic' ? 'الغير مقروء' : 'Unread'
    },
    {
      value: 'true',
      label: lang == 'Arabic' ? 'المقروء' : 'Read'
    },

    {
      value: 'all',
      label: lang == 'Arabic' ? 'الكل' : 'All'
    }
  ]

  useEffect(() => {
    dispatch(updateNotificationCount(counter))
  }, [counter])

  useEffect(() => {
    calculated = -1
  }, [listing?.id])

  const countNotifications = async () => {
    try {
      let count = 0
      const hasCalculated = calculated > -1

      const { data: response } = await axios.post(
        API.NOTIFICATIONS_COUNT,
        {
          listing_type: listing?.listing_type,
          listing_id: listing?.id,
          read_at: 'false'
        },
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )

      if (response.code == 200 && response.data) {
        count = response.data?.count
      } else {
        count = 0
      }
      if (open && count != listingCount && tab == 0) {
        getNotificationData()
      }
      setListingCount(count)
      const resp = await axios.post(
        API.NOTIFICATIONS_COUNT,
        {
          listing_type: null,
          read_at: 'false'
        },
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )
      if (resp?.data?.code == 200 && resp?.data?.data) {
        const accountLength = resp.data.data?.count
        if (open && accountLength != accountCount && tab == 1) {
          getNotificationData()
        }
        setAccountCount(accountLength)
        count += accountLength
      } else {
        count += 0
      }
      let soundEnabled = localStorage.getItem('notificationSoundEnabled')

      if (soundEnabled && hasCalculated && count > calculated) {
        audio.src = soundFiles[soundEnabled] ?? soundFiles['Start']
        // console.log({ calculated, count }, soundFiles[soundEnabled])
        debounceFunction(() => {
          audio.play()
        }, 1500)
      }

      setCounter(count)
      calculated = count
    } catch (e) {
      setCounter(0)
    }
  }
  const readNotification = async (
    notification,
    open = false,
    operation = 'read'
  ) => {
    try {
      if (
        open &&
        notification.reference != 'booking' &&
        notification.reference != 'booked unit'
      ) {
        return
      }
      const payload = {
        operation,
        merchant_notifications: [notification.notification_id]
      }
      const { data: response } = await axios.post(
        API.NOTIFICATIONS_UPDATE,
        payload
      )

      if (response.code == 200 && response.data) {
        getNotificationData()
        if (open) {
          if (notification.reference == 'booking') navigate('/bookings')
        }
      } else {
      }
    } catch (e) {}
  }
  const markAllAsRead = async () => {
    try {
      const payload = {
        operation: 'read',
        merchant_notifications: notifications
          .filter(n => !n.read_at)
          .map(n => n.notification_id)
      }
      const { data: response } = await axios.post(
        API.NOTIFICATIONS_UPDATE,
        payload
      )

      if (response.code == 200 && response.data) {
        getNotificationData()
      } else {
      }
    } catch (e) {}
  }
  useEffect(() => {
    if (value != 'true' && listing?.id) {
      throttleFunction(countNotifications, 100)
    }
  }, [notifications, value, listing?.id])
  useEffect(() => {
    let temp = {}
    if (tab == 0) {
      temp = {
        listing_type: listing?.listing_type,
        listing_id: listing?.id
      }
    } else {
      temp = {
        listing_type: null
      }
    }

    if (value != 'all') {
      temp = {
        ...temp,
        read_at: value
      }
    }
    setPayload(prev => ({
      ...temp
    }))
  }, [tab, value, listing?.id])

  useEffect(() => {
    if (!listing?.id) return

    const countInterval = setInterval(() => {
      countNotifications()
    }, 1000 * 60 * 5)

    return () => {
      clearInterval(countInterval)
    }
  }, [listing?.id])

  useEffect(() => {
    getNotificationData()
  }, [payload])

  const getNotificationData = async () => {
    if (!listing?.id) return
    try {
      const { data: response } = await axios.post(API.NOTIFICATIONS, payload, {
        'axios-retry': {
          retries: globalRetriesCount
        }
      })

      if (response.code == 200 && response.data) {
        setNotifications(response.data)
      } else {
      }
    } catch (e) {}
  }

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null)

  const handleToggle = () => {
    if (value != 'false') {
      setValue('false')
    } else {
      if (listing?.id) countNotifications()
    }

    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (value != 'false') {
      setValue('false')
    } else {
      if (listing?.id) countNotifications()
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
    if (open) {
      getNotificationData()
    }
  }, [open])

  const handleChange = event => {
    if (event?.target.value) setValue(event?.target.value)
  }

  function a11yProps (index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }
  return (
    <>
      <Box
        sx={{
          ml: 0,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <Badge
          overlap='circular'
          color='error'
          badgeContent={counter}
          // variant='dot'
        >
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant='rounded'
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.dark.main
                    : theme.palette.secondary.light,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.warning.dark
                    : theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.warning.dark
                      : theme.palette.secondary.dark,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : theme.palette.secondary.light
                }
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
              color='inherit'
            >
              <IconBell stroke={1.5} size='1.3rem' />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? 'top' : 'top-right'}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ pt: 2, px: 2, minWidth: '300px' }}
                      >
                        <Grid item>
                          <Typography variant='subtitle1'>
                            {lang == 'Arabic' ? 'الإشعارات' : 'Notifications'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: '100%',
                          maxHeight: 'calc(100vh - 205px)',
                          overflowX: 'hidden'
                        }}
                      >
                        <Grid container direction='column' spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              mx: 2,
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Tabs
                              value={tab}
                              indicatorColor='primary'
                              textColor='primary'
                              onChange={(event, newValue) => setTab(newValue)}
                              aria-label='simple tabs example'
                              variant='fullWidth'
                              sx={{
                                mb: 3,
                                '& a': {
                                  minHeight: 'auto',
                                  minWidth: 10,
                                  py: 1.5,
                                  px: 0,
                                  mx: 2,
                                  color: theme.palette.grey[600],
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                },
                                '& a.Mui-selected': {
                                  color: theme.palette.primary.main
                                },
                                '& .MuiTabs-indicator': {
                                  bottom: 2
                                },
                                '& a > svg': {
                                  marginBottom: '0px !important',
                                  mr: 1.25
                                }
                              }}
                            >
                              {tabsOption.map((tab, index) => (
                                <Tab
                                  key={index}
                                  component={Link}
                                  to='#'
                                  //   icon={tab.icon}
                                  style={{
                                    overflow: 'visible',
                                    marginRight: '20px'
                                  }}
                                  label={
                                    <div>
                                      {tab.label}
                                      <Badge
                                        overlap='circular'
                                        color='error'
                                        badgeContent={
                                          tab.label == 'Listing'
                                            ? listingCount
                                            : accountCount
                                        }
                                        sx={{
                                          width: '100%',
                                          position: 'absolute',
                                          top: 10,
                                          right: -5
                                        }}
                                        // variant='dot'
                                      ></Badge>
                                    </div>
                                  }
                                  {...a11yProps(index)}
                                />
                              ))}
                            </Tabs>
                            <Grid item xs={12}>
                              <Grid
                                container
                                // justifyContent={'space-between'}
                                alignItems='center'
                              >
                                <Grid item width={'50%'}>
                                  <TextField
                                    id='outlined-select-currency-native'
                                    select
                                    fullWidth
                                    value={value}
                                    onChange={handleChange}
                                    SelectProps={{
                                      native: true
                                    }}
                                  >
                                    {status.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                                {value != 'true' && notifications.length > 0 && (
                                  <Grid item paddingLeft={'15px'}>
                                    <Typography
                                      component={Link}
                                      to='#'
                                      onClick={markAllAsRead}
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {lang == 'Arabic'
                                        ? 'تحديد الكل كمقروء'
                                        : 'Mark all as read'}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>

                        <NotificationList
                          readNotification={readNotification}
                          discardNotification={(notification, open) =>
                            readNotification(notification, open, 'discard')
                          }
                          notifications={notifications}
                        />
                        {notifications?.length < 1 && (
                          <Grid
                            justifyContent='center'
                            alignItems='center'
                            display={'flex'}
                            height={100}
                          >
                            <Typography>
                              {value == 'all'
                                ? lang == 'Arabic'
                                  ? 'لا توجد إشعارات'
                                  : 'No notifications'
                                : value == 'true'
                                ? lang == 'Arabic'
                                  ? 'لا توجد إشعارات مقروءة'
                                  : 'No read notifications'
                                : lang == 'Arabic'
                                ? 'لا توجد إشعارات جديدة'
                                : 'No new notifications'}
                            </Typography>
                          </Grid>
                        )}
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                    <Button size='small' disableElevation>
                      View All
                    </Button>
                  </CardActions> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default NotificationSection
