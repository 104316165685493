import moment from 'moment'
import { useDispatch } from 'react-redux'
import { SET_FONT_FAMILY, THEME_RTL } from 'store/actions'

const { createContext, useState, useEffect } = require('react')

const LanguageContext = createContext('')

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    // if (lang) {
    dispatch({ type: THEME_RTL, rtlLayout: lang == 'Arabic' })
    dispatch({
      type: SET_FONT_FAMILY,
      fontFamily: lang == 'Arabic' ? 'Montserrat-Arabic' : 'SegoeUI'
    })
    // }

    // if (lang == 'Arabic') {
    //   moment.locale('ar')
    // } else {
    //   moment.locale('en')
    // }
    if (lang) {
      const months = [
        lang == 'Arabic' ? 'يناير' : 'January',
        lang == 'Arabic' ? 'فبراير' : 'February',
        lang == 'Arabic' ? 'مارس' : 'March',
        lang == 'Arabic' ? 'أبريل' : 'April',
        lang == 'Arabic' ? 'مايو' : 'May',
        lang == 'Arabic' ? 'يونيو' : 'June',
        lang == 'Arabic' ? 'يوليو' : 'July',
        lang == 'Arabic' ? 'أغسطس' : 'August',
        lang == 'Arabic' ? 'سبتمبر' : 'September',
        lang == 'Arabic' ? 'أكتوبر' : 'October',
        lang == 'Arabic' ? 'نوفمبر' : 'November',
        lang == 'Arabic' ? 'ديسمبر' : 'December'
      ]
      const shortMonths = [
        lang == 'Arabic' ? 'يناير' : 'Jan',
        lang == 'Arabic' ? 'فبراير' : 'Feb',
        lang == 'Arabic' ? 'مارس' : 'Mar',
        lang == 'Arabic' ? 'أبريل' : 'Apr',
        lang == 'Arabic' ? 'مايو' : 'May',
        lang == 'Arabic' ? 'يونيو' : 'Jun',
        lang == 'Arabic' ? 'يوليو' : 'Jul',
        lang == 'Arabic' ? 'أغسطس' : 'Aug',
        lang == 'Arabic' ? 'سبتمبر' : 'Sept',
        lang == 'Arabic' ? 'أكتوبر' : 'Oct',
        lang == 'Arabic' ? 'نوفمبر' : 'Nov',
        lang == 'Arabic' ? 'ديسمبر' : 'Dec'
      ]

      const weekdaysShort =
        lang == 'Arabic'
          ? ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
          : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

      moment.updateLocale('en', {
        weekdaysShort,
        months: months,
        monthsShort: shortMonths,
        relativeTime: {
          future: lang == 'Arabic' ? 'بعد %s' : 'in %s',
          past: lang == 'Arabic' ? 'قبل %s' : '%s ago',
          s: lang == 'Arabic' ? 'بضع ثوان' : 'a few seconds',
          ss: lang == 'Arabic' ? '%d ثوان' : '%d seconds',
          m: lang == 'Arabic' ? 'دقيقة' : 'a minute',
          mm: lang == 'Arabic' ? '%d دقائق' : '%d minutes',
          h: lang == 'Arabic' ? 'ساعة' : 'an hour',
          hh: lang == 'Arabic' ? '%d ساعات' : '%d hours',
          d: lang == 'Arabic' ? 'يوم' : 'a day',
          dd: lang == 'Arabic' ? '%d أيام' : '%d days',
          w: lang == 'Arabic' ? 'أسبوع' : 'a week',
          ww: lang == 'Arabic' ? '%d أسابيع' : '%d weeks',
          M: lang == 'Arabic' ? 'شهر' : 'a month',
          MM: lang == 'Arabic' ? '%d أشهر' : '%d months',
          y: lang == 'Arabic' ? 'عام' : 'a year',
          yy: lang == 'Arabic' ? '%d أعوام' : '%d years'
        },

        meridiem: function (hour, minute, isLowercase) {
          if (hour >= 12) {
            return lang == 'Arabic' ? 'م' : isLowercase ? 'pm' : 'PM'
          } else {
            return lang == 'Arabic' ? 'ص' : isLowercase ? 'am' : 'AM'
          }
        }
      })
      localStorage.setItem('lang', lang)
    }
  }, [lang])

  useEffect(() => {
    const saved = localStorage.getItem('lang')
    if (saved) {
      setLang(saved)
    } else {
      setLang('English')
    }
  })

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
