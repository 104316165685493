import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { gridSpacing } from 'store/constant'
import MainCard from 'ui-component/cards/MainCard'
import CustomDatePicker from 'ui-component/date-time/CustomDatePicker'
import Loader from 'ui-component/Loader'
import RadioSelect from 'ui-component/RadioSelect'
import { useTheme } from '@mui/material/styles'
import Accordion from 'ui-component/extended/Accordion'
import { useDispatch, useSelector } from 'react-redux'
import {
  capitalize,
  getBookingResources,
  getBookingServices
} from 'store/actions/bookingActions'
import axios, { globalRetriesCount } from 'utils/axios'
import API from 'utils/constants/API'
import SimpleTable from 'ui-component/tables/SimpleTable'
import CustomSelect from 'ui-component/CustomSelect'
import { read, utils, writeFileXLSX } from 'xlsx'
import moment from 'moment'
import CustomPeriod from 'ui-component/date-time/CustomPeriod'
import { min_credit_bal, SNACKBAR_OPEN } from 'store/actions'
import useAuth from 'hooks/useAuth'
import useLang from 'hooks/useLang'

const BookingReports = () => {
  const { lang } = useLang()
  const [loading, setloading] = useState(false)
  const [report, setReport] = useState({ type: 'bookings', groups: [] })

  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'))
  const [groupResources, setGroupResources] = useState(false)
  const [groupServices, setGroupServices] = useState(false)

  const [canGenerate, setCanGenerate] = useState(false)
  const [errors, setErrors] = useState({})
  const [hasOneDate, sethasOneDate] = useState(true)
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [customColumns, setCustomColumns] = useState([])
  const [generated, setGenerated] = useState(false)

  const bookingInitialFilter = {
    from_bookings_created_date: null,
    to_bookings_created_date: null,
    from_bookings_updated_date: null,
    to_bookings_updated_date: null,
    from_booked_units_created_date: null,
    to_booked_units_created_date: null,
    from_booked_units_updated_date: null,
    to_booked_units_updated_date: null,
    from_starting_date: null,
    to_starting_date: null,
    canceled_by_null: true,
    canceled_by_merchant: false,
    canceled_by_user: false
  }

  const bookedUnitInitialFilter = {
    from_bookings_created_date: null,
    to_bookings_created_date: null,
    from_bookings_updated_date: null,
    to_bookings_updated_date: null,
    from_booked_units_created_date: null,
    to_booked_units_created_date: null,
    from_booked_units_updated_date: null,
    to_booked_units_updated_date: null,
    from_starting_date: null,
    to_starting_date: null,
    cancel_from: null
  }

  const [filters, setFilters] = useState(bookingInitialFilter)
  const [applyFilters, setApplyFilters] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth()

  const listing = useSelector(
    ({ listingsStore }) => listingsStore.selectedListing
  )

  const bookingSchemes = useSelector(
    ({ bookingStore }) => bookingStore.bookingSchemes
  )

  const services = useSelector(({ bookingStore }) =>
    bookingStore.bookingServices.map(service => {
      service.id = service.booking_service_id
      return service
    })
  )

  const resources = useSelector(({ bookingStore }) =>
    bookingStore.bookingResources.map(resource => {
      resource.id = resource.booking_resource_id
      return resource
    })
  )

  const dateHasError = (start, end) => {
    const enddate = filters[end] ? moment(filters[end]) : moment()
    const dateDiff = enddate.diff(moment(filters[start]), 'month')
    setErrors(prev => {
      prev[start] =
        dateDiff > 3 || dateDiff < 0 || (filters[end] && !filters[start])
          ? lang == 'Arabic'
            ? 'النطاق الزمني لا يمكن أن يتجاوز 3 أشهر'
            : 'Date range cannot be greater than 3 months'
          : false
      return prev
    })
  }

  useEffect(() => {
    let hasDate = null
    const checkFilters = () => {
      if (
        filters.from_bookings_created_date ||
        filters.to_bookings_created_date
      ) {
        hasDate =
          filters.from_bookings_created_date ||
          (filters.from_bookings_created_date &&
            filters.to_bookings_created_date)
        dateHasError('from_bookings_created_date', 'to_bookings_created_date')
      } else {
        setErrors(prev => ({ ...prev, from_bookings_created_date: null }))
      }

      if (
        filters.from_bookings_updated_date ||
        filters.to_bookings_updated_date
      ) {
        hasDate =
          hasDate ??
          (filters.from_bookings_updated_date ||
            (filters.from_bookings_updated_date &&
              filters.to_bookings_updated_date))
        dateHasError('from_bookings_updated_date', 'to_bookings_updated_date')
      } else {
        setErrors(prev => ({ ...prev, from_bookings_updated_date: null }))
      }

      if (filters.from_starting_date || filters.to_starting_date) {
        hasDate =
          hasDate ??
          (filters.from_starting_date ||
            (filters.to_starting_date && filters.from_starting_date))
        dateHasError('from_starting_date', 'to_starting_date')
      } else {
        setErrors(prev => ({ ...prev, from_starting_date: null }))
      }

      if (
        filters.from_booked_units_created_date ||
        filters.to_booked_units_created_date
      ) {
        hasDate =
          hasDate ??
          (filters.from_booked_units_created_date ||
            (filters.from_booked_units_created_date &&
              filters.to_booked_units_created_date))
        dateHasError(
          'from_booked_units_created_date',
          'to_booked_units_created_date'
        )
      } else {
        setErrors(prev => ({ ...prev, from_booked_units_created_date: null }))
      }

      if (
        filters.from_booked_units_updated_date ||
        filters.to_booked_units_updated_date
      ) {
        hasDate =
          hasDate ??
          (filters.from_booked_units_updated_date ||
            (filters.from_booked_units_updated_date &&
              filters.to_booked_units_updated_date))
        dateHasError(
          'from_booked_units_updated_date',
          'to_booked_units_updated_date'
        )
      } else {
        setErrors(prev => ({ ...prev, from_booked_units_updated_date: null }))
      }
    }

    checkFilters()
    sethasOneDate(hasDate)
  }, [filters])

  useEffect(() => {
    const oneValidFilter = Object.values(errors).filter(err => err == false)
    setCanGenerate(hasOneDate && oneValidFilter.length > 0)
  }, [errors, hasOneDate])

  useEffect(() => {
    setGroupResources(resources?.some(r => r.group))
    setGroupServices(services?.some(r => r.group))
  }, [services, resources])

  const [groups, setGroups] = useState([
    {
      id: 'resource_name',
      name:
        lang == 'Arabic'
          ? bookingSchemes.resource_si_ar
          : capitalize(bookingSchemes.resource_si)
    },
    {
      id: 'service_name',
      name:
        lang == 'Arabic'
          ? bookingSchemes.service_si_ar
          : capitalize(bookingSchemes.service_si)
    },
    { id: 'status', name: lang == 'Arabic' ? 'الحالة' : 'Status' }
  ])

  useEffect(() => {
    const isAmount = true
    if (report.type == 'bookings') {
      setColumns([
        {
          id: 'name',
          label: lang == 'Arabic' ? 'الإسم' : 'Name',
          minWidth: 1
        },
        {
          id: 'phone',
          label: lang == 'Arabic' ? 'الهاتف' : 'Phone',
          minWidth: 1
        },
        {
          id: 'notes',
          label: lang == 'Arabic' ? 'الملاحظات' : 'Notes',
          minWidth: 1
        },
        {
          id: 'booked_by',
          label: lang == 'Arabic' ? 'محجوز من قبل' : 'Booked by',
          minWidth: 1
        },
        {
          id: 'created_at',
          label: lang == 'Arabic' ? 'تم إنشاؤه' : 'Created',
          minWidth: 1
        },
        {
          id: 'updated_at',
          label: lang == 'Arabic' ? 'تم تحديثه' : 'Updated',
          minWidth: 1
        },
        {
          id: 'booking_uuid',
          label: lang == 'Arabic' ? 'UUID' : 'UUID',
          minWidth: 1
        },
        {
          id: 'service_amount_sum',
          label:
            lang == 'Arabic'
              ? `مجموع مبلغ ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} amount sum`,
          minWidth: 1,
          isAmount
        },
        {
          id: 'service_amount_average',
          label:
            lang == 'Arabic'
              ? `متوسط مبلغ ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} amount average`,
          minWidth: 1,
          isAmount
        },
        {
          id: bookingSchemes.sc_quantity ? 'quantity_sum' : null,
          label: bookingSchemes.sc_quantity
            ? lang == 'Arabic'
              ? `مجموع ${bookingSchemes.quantity_pl_ar}`
              : `${capitalize(bookingSchemes.quantity_pl)} sum`
            : '',
          minWidth: 1,
          isAmount
        },
        {
          id: bookingSchemes.sc_quantity ? 'quantity_average' : null,
          label: bookingSchemes.sc_quantity
            ? lang == 'Arabic'
              ? `متوسط ${bookingSchemes.quantity_pl_ar}`
              : `${capitalize(bookingSchemes.quantity_pl)} average`
            : '',
          minWidth: 1,
          isAmount
        },

        {
          id: 'option_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخيار' : 'Option amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط مبلغ الخيار' : 'Option amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_sum',
          label: lang == 'Arabic' ? 'مجموع عدد الخيار' : 'Option quantity sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_average',
          label:
            lang == 'Arabic' ? 'متوسط عدد الخيار' : 'Option quantity average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_before_discount_sum',
          label:
            lang == 'Arabic'
              ? 'مجموع المبلغ الإجمالي قبل الخصم'
              : 'Total amount before discount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_before_discount_average',
          label:
            lang == 'Arabic'
              ? 'متوسط المبلغ الإجمالي قبل الخصم'
              : 'Total amount before discount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'discount_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخصم' : 'Discount amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'discount_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط مبلغ الخصم' : 'Discount amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_sum',
          label:
            lang == 'Arabic' ? 'مجموع المبلغ الإجمالي' : 'Total amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط المبلغ الإجمالي' : 'Total amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'number_of_booked_units',
          label:
            lang == 'Arabic'
              ? `عدد ${bookingSchemes.booked_unit_pl_ar}`
              : `Number of ${bookingSchemes.booked_unit_pl}`,
          minWidth: 1,
          isAmount
        },
        {
          id: 'paid_amount',
          label: lang == 'Arabic' ? 'المبلغ المدفوع' : 'Paid amount',
          minWidth: 1,
          isGreen: true
        }
        // { id: 'paid_amount', label: 'Paid amount', minWidth: 1, isAmount }
      ])
    } else if (report.type == 'booked_units') {
      setColumns([
        {
          id: 'booked_date',
          label: lang == 'Arabic' ? 'التاريخ المحجوز' : 'Booked date',
          minWidth: 1
        },
        {
          id: 'booked_time',
          label: lang == 'Arabic' ? 'الوقت المحجوز' : 'Booked time',
          minWidth: 1
        },
        // { id: 'booking_booked_by', label: 'Booked by', minWidth: 1 },
        {
          id: bookingSchemes.sc_quantity ? 'quantity' : null,
          label: bookingSchemes.sc_quantity
            ? lang == 'Arabic'
              ? bookingSchemes.quantity_pl_ar
              : capitalize(bookingSchemes.quantity_pl)
            : '',
          minWidth: 0
        },
        {
          id: 'resource_name',
          label:
            lang == 'Arabic'
              ? `اسم ${bookingSchemes.resource_si_ar}`
              : `${capitalize(bookingSchemes.resource_si)} name`,
          minWidth: 1
        },
        {
          id: 'service_name',
          label:
            lang == 'Arabic'
              ? `اسم ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} name`,
          minWidth: 1
        },
        {
          id: 'booking_option_names',
          label: lang == 'Arabic' ? 'خيارات الحجز' : 'Booking options',
          minWidth: 1
        },
        {
          id: 'discount_code',
          label: lang == 'Arabic' ? 'كود الخصم' : 'Discount code',
          minWidth: 1
        },
        {
          id: 'discount_percent',
          label: lang == 'Arabic' ? 'نسبة الخصم' : 'Discount percentage',
          minWidth: 1
        },
        {
          id: 'down_payment_percent',
          label:
            lang == 'Arabic'
              ? 'نسبة الدفع المسبق'
              : 'Advance payment percentage',
          minWidth: 1
        },
        {
          id: 'status',
          label: lang == 'Arabic' ? 'الحالة' : 'Status',
          minWidth: 1
        },
        {
          id: 'canceled_by',
          label: lang == 'Arabic' ? 'ملغي من قبل' : 'Canceled by',
          minWidth: 1
        },
        {
          id: 'created_at',
          label:
            lang == 'Arabic'
              ? `تم إنشاء ${bookingSchemes.booked_unit_si_ar}`
              : `${capitalize(bookingSchemes.booked_unit_si)} created`,
          minWidth: 1
        },
        {
          id: 'updated_at',
          label:
            lang == 'Arabic'
              ? `تم تحديث ${bookingSchemes.booked_unit_si_ar}`
              : `${capitalize(bookingSchemes.booked_unit_si)} updated`,
          minWidth: 1
        },
        {
          id: 'booked_unit_uuid',
          label:
            lang == 'Arabic'
              ? `UUID ${bookingSchemes.booked_unit_si_ar}`
              : `${capitalize(bookingSchemes.booked_unit_si)} UUID`,
          minWidth: 1
        },
        {
          id: 'booking_name',
          label: lang == 'Arabic' ? 'اسم الحجز' : 'Booking name',
          minWidth: 1
        },
        {
          id: 'booking_phone',
          label: lang == 'Arabic' ? 'هاتف الحجز' : 'Booking phone',
          minWidth: 1
        },
        {
          id: 'booking_notes',
          label: lang == 'Arabic' ? 'ملاحظات الحجز' : 'Booking notes',
          minWidth: 1
        },
        {
          id: 'booking_booked_by',
          label: lang == 'Arabic' ? 'الحجز محجوز من قبل' : 'Booking booked by',
          minWidth: 1
        },
        {
          id: 'booking_created_at',
          label:
            lang == 'Arabic'
              ? `تم إنشاء ${bookingSchemes.booking_si_ar}`
              : `${capitalize(bookingSchemes.booking_si)} created`,
          minWidth: 1
        },
        {
          id: 'booking_updated_at',
          label:
            lang == 'Arabic'
              ? `تم تحديث ${bookingSchemes.booking_si_ar}`
              : `${capitalize(bookingSchemes.booking_si)} updated`,
          minWidth: 1
        },
        {
          id: 'booking_uuid',
          label:
            lang == 'Arabic'
              ? `UUID ${bookingSchemes.booking_si_ar}`
              : `${capitalize(bookingSchemes.booking_si)} UUID`,
          minWidth: 1
        },
        {
          id: 'service_amount_sum',
          label:
            lang == 'Arabic'
              ? `مجموع مبلغ ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} amount sum`,
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخيار' : 'Option amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط مبلغ الخيار' : 'Option amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_sum',
          label: lang == 'Arabic' ? 'مجموع عدد الخيار' : 'Option quantity sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_average',
          label:
            lang == 'Arabic' ? 'متوسط عدد الخيار' : 'Option quantity average',
          minWidth: 1,
          isAmount
        },

        {
          id: 'total_amount_before_discount_sum',
          label:
            lang == 'Arabic'
              ? 'مجموع المبلغ الإجمالي قبل الخصم'
              : 'Total amount before discount sum',
          minWidth: 1,
          isAmount
        },

        {
          id: 'discount_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخصم' : 'Discount amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_sum',
          label:
            lang == 'Arabic' ? 'مجموع المبلغ الإجمالي' : 'Total amount sum',
          minWidth: 1,
          isAmount
        }
        // { id: 'total_options', label: 'Total options', minWidth: 1, isAmount }
        // { id: 'paid_amount', label: 'Paid amount', minWidth: 1, isAmount }
      ])
    } else {
      setCustomColumns([])
    }
    setReport(prev => ({ ...prev, groups: [] }))
    setGenerated(false)
  }, [report.type, bookingSchemes, lang])

  const getResourceAndServices = async () => {
    try {
      if (listing?.title) {
        await dispatch(
          getBookingResources({
            listing_id: listing?.id,
            listing_type: listing?.listing_type
          })
        )
        await dispatch(
          getBookingServices({
            listing_id: listing?.id,
            listing_type: listing?.listing_type
          })
        )
      }
    } catch (e) {}
  }

  useEffect(() => {
    getResourceAndServices()
    setGenerated(false)
  }, [listing?.title])

  useEffect(() => {
    setFilters(prev => ({
      ...prev,
      from_bookings_created_date: moment()
        .subtract(1, 'day')
        .format('yyyy-MM-DD'),
      to_bookings_created_date: moment().subtract(1, 'day').format('yyyy-MM-DD')
    }))
  }, [])

  useEffect(() => {
    if (user && user?.credit_balance < min_credit_bal)
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message:
          lang == 'Arabic'
            ? 'تتطلب هذه الميزة حد أدنى من الرصيد لاستخدامها؛ يرجى مراجعة معدلات الاستخدام في قسم الحساب.'
            : 'This feature requires a minimum balance of credits to be utilized; please review the credits utilization rates in the account section.',
        variant: 'alert',
        alertSeverity: 'warning'
      })
  }, [user])

  const getAllBookings = async () => {
    const options = {
      listing_id: listing?.id,
      listing_type: listing?.listing_type
    }

    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const val = filters[key]

        if (val === 'Invalid date') {
          continue
        }

        if (
          key == 'canceled_by' &&
          val == 'has_one_cancellation' &&
          filters.cancel_from != '0'
        ) {
          options[key] = filters.cancel_from
        } else if (
          val &&
          key != 'cancel_from' &&
          val != 'has_one_cancellation'
        ) {
          options[key] = val
        }
      }
    }
    let group = 1
    const allgroups = report?.groups ?? []
    let tableColumns = []
    if (allgroups.length > 0) {
      for (const key in allgroups) {
        if (Object.hasOwnProperty.call(allgroups, key)) {
          options['grouping_' + group] = allgroups[key]?.id
          tableColumns.push({
            id: allgroups[key]?.id,
            label: allgroups[key]?.name,
            minWidth: 1
          })
        }
        group += 1
      }
      const isAmount = true
      setCustomColumns([
        ...tableColumns,
        {
          id: 'service_amount_sum',
          label:
            lang == 'Arabic'
              ? `مجموع مبلغ ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} amount sum`,
          minWidth: 1,
          isAmount
        },
        {
          id: 'service_amount_average',
          label:
            lang == 'Arabic'
              ? `متوسط مبلغ ${bookingSchemes.service_si_ar}`
              : `${capitalize(bookingSchemes.service_si)} amount average`,
          minWidth: 1,
          isAmount
        },
        {
          id: bookingSchemes.sc_quantity ? 'quantity_sum' : null,
          label: bookingSchemes.sc_quantity
            ? lang == 'Arabic'
              ? `مجموع ${bookingSchemes.quantity_pl_ar}`
              : `${capitalize(bookingSchemes.quantity_pl)} sum`
            : '',
          minWidth: 1,
          isAmount
        },
        {
          id: bookingSchemes.sc_quantity ? 'quantity_average' : null,
          label: bookingSchemes.sc_quantity
            ? lang == 'Arabic'
              ? `متوسط ${bookingSchemes.quantity_pl_ar}`
              : `${capitalize(bookingSchemes.quantity_pl)} average`
            : '',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخيار' : 'Option amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط مبلغ الخيار' : 'Option amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_sum',
          label: lang == 'Arabic' ? 'مجموع عدد الخيار' : 'Option quantity sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'option_quantity_average',
          label:
            lang == 'Arabic' ? 'متوسط عدد الخيار' : 'Option quantity average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_before_discount_sum',
          label:
            lang == 'Arabic'
              ? 'مجموع المبلغ الإجمالي قبل الخصم'
              : 'Total amount before discount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_before_discount_average',
          label:
            lang == 'Arabic'
              ? 'متوسط المبلغ الإجمالي قبل الخصم'
              : 'Total amount before discount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'discount_amount_sum',
          label: lang == 'Arabic' ? 'مجموع مبلغ الخصم' : 'Discount amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'discount_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط مبلغ الخصم' : 'Discount amount average',
          minWidth: 1,
          isAmount
        },

        {
          id: 'total_amount_sum',
          label:
            lang == 'Arabic' ? 'مجموع المبلغ الإجمالي' : 'Total amount sum',
          minWidth: 1,
          isAmount
        },
        {
          id: 'total_amount_average',
          label:
            lang == 'Arabic' ? 'متوسط المبلغ الإجمالي' : 'Total amount average',
          minWidth: 1,
          isAmount
        },
        {
          id: 'number_of_booked_units',
          label:
            lang == 'Arabic'
              ? `إجمالي عدد ${bookingSchemes.booked_unit_pl_ar}`
              : `Total number of ${bookingSchemes.booked_unit_pl}`,
          minWidth: 1,
          isAmount
        }
      ])
    }

    try {
      const { data } = await axios.post(
        report.type == 'bookings'
          ? API.GET_BOOKINGS_REPORT
          : report.type == 'booked_units'
          ? API.GET_BOOKED_UNITS_REPORT
          : API.GET_CUSTOM_REPORT,
        options,
        {
          'axios-retry': {
            retries: globalRetriesCount
          }
        }
      )
      if (data.code == 200) {
        const resp = data.data
        const rws = resp.map(r => {
          return {
            ...r,
            booked_date: moment(r.starting_date_time).format('yyyy-MM-DD'),
            booked_time: moment(r.starting_date_time).format('hh:mm A'),
            name: r.first_name + ' ' + r.last_name,
            booking_name: r.booking_first_name + ' ' + r.booking_last_name,
            down_payment_percent:
              (parseFloat(r?.down_payment_percent) * 100).toFixed() + '%',
            discount_percent:
              (parseFloat(r.discount_percent) * 100).toFixed() + '%'
          }
        })
        setRows(rws)
      } else {
        setRows([])
      }
    } catch (e) {}
  }

  const exportReport = () => {
    const allCol = report.type == 'custom' ? customColumns : columns
    const header = allCol
      .filter(c => c?.id && c?.label)
      .map(c => {
        return c.label
      })

    const body = rows.map(r => {
      const row = {}

      allCol.forEach(col => {
        if (col?.id) {
          if (col.id != 'booking_id' && col.id != 'phone') {
            if (col.id) row[col.id] = r[col.id]
          } else if (col.id == 'phone') {
            if (col.id) {
              const value = parseInt(r[col.id])
              row[col.id] = r[col.id]
            }
          }
        }
      })
      if (Object.keys(row).length > 1) return row
    })

    const ws = utils.json_to_sheet(body)

    utils.sheet_add_aoa(ws, [header], {
      origin: 'A1'
    })

    /* calculate column width */
    const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10)
    ws['!cols'] = [{ wch: max_width }]

    const wb = utils.book_new()
    utils.book_append_sheet(
      wb,
      ws,
      report.type == 'booked_units'
        ? lang == 'Arabic'
          ? bookingSchemes.booked_unit_pl_ar
          : capitalize(bookingSchemes.booked_unit_pl)
        : report.type == 'custom'
        ? lang == 'Arabic'
          ? 'التجميع المخصص'
          : 'Custom grouping'
        : lang == 'Arabic'
        ? bookingSchemes.booking_pl_ar
        : capitalize(bookingSchemes.booking_pl)
    )
    writeFileXLSX(
      wb,
      (report.type == 'booked_units'
        ? lang == 'Arabic'
          ? `تقرير ${bookingSchemes.booked_unit_pl_ar}`
          : `${capitalize(bookingSchemes.booked_unit_pl)} report`
        : report.type == 'custom'
        ? lang == 'Arabic'
          ? 'تقرير التجميع المخصص'
          : 'Custom grouping report'
        : lang == 'Arabic'
        ? `تقرير ${bookingSchemes.booking_pl_ar}`
        : `${capitalize(bookingSchemes.booking_pl)} report`) + '.xlsx'
    )
  }

  return (
    <MainCard title={lang == 'Arabic' ? 'تقارير الحجز' : 'Booking reports'}>
      {loading && <Loader />}
      <Typography fontWeight={'bold'} style={{ marginBottom: '20px' }}>
        {lang == 'Arabic' ? 'نمط التقرير' : 'Reporting style'}
      </Typography>
      <RadioGroup
        row
        // aria-label='Type'
        value={report.type}
        onChange={e => {
          const val = e.target?.value

          setFilters({
            ...(val == 'bookings'
              ? bookingInitialFilter
              : bookedUnitInitialFilter),
            from_bookings_created_date: moment()
              .subtract(1, 'day')
              .format('yyyy-MM-DD'),
            to_bookings_created_date: moment()
              .subtract(1, 'day')
              .format('yyyy-MM-DD')
          })

          setReport(prev => ({
            ...prev,
            type: val
          }))
        }}
      >
        <Grid container spacing={gridSpacing} alignItems='center'>
          <Grid item md={4} xs={12}>
            <RadioSelect
              item={{
                title:
                  lang == 'Arabic'
                    ? bookingSchemes.booking_pl_ar
                    : capitalize(bookingSchemes.booking_pl),
                value: 'bookings',
                caption:
                  lang == 'Arabic'
                    ? 'مراجعة كافة السجلات على مستوى المعاملات'
                    : 'Review all of your transaction-level records'
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <RadioSelect
              item={{
                title:
                  lang == 'Arabic'
                    ? bookingSchemes.booked_unit_pl_ar
                    : capitalize(bookingSchemes.booked_unit_pl),
                value: 'booked_units',
                caption:
                  lang == 'Arabic'
                    ? 'الحصول على كل التفاصيل لمزيد من العمق في التحليل'
                    : 'Get every detail for a greater depth of analysis'
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <RadioSelect
              item={{
                title: lang == 'Arabic' ? 'التجميع المخصص' : 'Custom grouping',
                value: 'custom',
                caption:
                  lang == 'Arabic'
                    ? 'التقسيم والتحليل بناء على احتياجاتك'
                    : 'Breakdown and analyze based on your needs'
              }}
            />
          </Grid>
        </Grid>
      </RadioGroup>

      <MainCard border={!matchDownLG} content={false} sx={{ padding: '20px' }}>
        <Accordion
          data={[
            {
              id: 'booked_unit_level',
              defaultExpand: true,
              title: (
                <Grid item xs={12} sx={{ my: '0px' }}>
                  <Typography fontWeight={'bold'}>
                    {lang == 'Arabic'
                      ? `تصفية مستوى ${bookingSchemes.booking_si_ar}`
                      : `${capitalize(bookingSchemes.booking_si)} level filter`}
                  </Typography>
                </Grid>
              ),
              content: (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{ m: 0, justifyContent: 'space-between' }}
                  >
                    <Grid container spacing={2} sx={{ mb: 2 }} xs={12} md={6}>
                      <Grid
                        display={'flex'}
                        item
                        xs={12}
                        justifyContent='space-between'
                      >
                        <Typography fontWeight={'bold'}>
                          {lang == 'Arabic'
                            ? `تم إنشاء ${bookingSchemes.booking_pl_ar} في`
                            : `${capitalize(
                                bookingSchemes.booking_pl
                              )} created on`}
                        </Typography>
                        <CustomPeriod
                          startDate={filters.from_bookings_created_date}
                          endDate={filters.to_bookings_created_date}
                          onPeriodChange={filter => {
                            setFilters(prev => ({
                              ...prev,
                              from_bookings_created_date: filter.start,
                              to_bookings_created_date: filter.end
                            }))
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} sx={{ mb: '15px' }}>
                        <CustomDatePicker
                          value={filters.from_bookings_created_date}
                          minDate={null}
                          maxDate={new Date()}
                          title={
                            lang == 'Arabic' ? 'تاريخ البدء' : 'Start date'
                          }
                          name='from_bookings_created_date'
                          onChange={val => {
                            setFilters(prev => ({
                              ...prev,
                              from_bookings_created_date: val
                            }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomDatePicker
                          value={filters.to_bookings_created_date}
                          minDate={null}
                          maxDate={new Date()}
                          title={
                            lang == 'Arabic' ? 'تاريخ الانتهاء' : 'End date'
                          }
                          name='to_bookings_created_date'
                          onChange={val => {
                            setFilters(prev => ({
                              ...prev,
                              to_bookings_created_date: val
                            }))
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{ pt: '0 !important' }}
                        display='flex'
                        justifyContent={
                          'end'
                          // errors?.from_bookings_created_date
                          //   ? 'space-between'
                          //   : 'flex-end'
                        }
                      >
                        {/* <FormHelperText error>
                          {errors?.from_bookings_created_date}
                        </FormHelperText> */}
                        <Button
                          onClick={() =>
                            setFilters(prev => ({
                              ...prev,
                              from_bookings_created_date: null,
                              to_bookings_created_date: null
                            }))
                          }
                        >
                          {lang == 'Arabic' ? 'مسح' : 'Clear'}
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }} xs={12} md={6}>
                      <Grid
                        item
                        xs={12}
                        display='flex'
                        justifyContent={'space-between'}
                      >
                        <Typography fontWeight={'bold'}>
                          {lang == 'Arabic'
                            ? `تم تحديث ${capitalize(
                                bookingSchemes.booking_pl_ar
                              )} في`
                            : `${capitalize(
                                bookingSchemes.booking_pl
                              )} updated on`}
                        </Typography>
                        <CustomPeriod
                          startDate={filters.from_bookings_updated_date}
                          endDate={filters.to_bookings_updated_date}
                          onPeriodChange={filter => {
                            setFilters(prev => ({
                              ...prev,
                              from_bookings_updated_date: filter.start,
                              to_bookings_updated_date: filter.end
                            }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ mb: '15px' }}>
                        <CustomDatePicker
                          value={filters.from_bookings_updated_date}
                          minDate={null}
                          maxDate={new Date()}
                          title={
                            lang == 'Arabic' ? 'تاريخ البدء' : 'Start date'
                          }
                          name='from_bookings_updated_date'
                          onChange={val => {
                            setFilters(prev => ({
                              ...prev,
                              from_bookings_updated_date: val
                            }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomDatePicker
                          value={filters.to_bookings_updated_date}
                          minDate={null}
                          maxDate={new Date()}
                          title={
                            lang == 'Arabic' ? 'تاريخ الانتهاء' : 'End date'
                          }
                          name='to_bookings_updated_date'
                          onChange={val => {
                            setFilters(prev => ({
                              ...prev,
                              to_bookings_updated_date: val
                            }))
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ pt: '0 !important' }}
                        display='flex'
                        justifyContent={'end'}
                      >
                        <Button
                          onClick={() =>
                            setFilters(prev => ({
                              ...prev,
                              to_bookings_updated_date: null,
                              from_bookings_updated_date: null
                            }))
                          }
                        >
                          {lang == 'Arabic' ? 'مسح' : 'Clear'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{ m: 0, justifyContent: 'space-between' }}
                  ></Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography fontWeight={'bold'}>
                        {lang == 'Arabic' ? 'محجوز من قبل' : 'Booked by'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: '0 !important' }}>
                      <RadioGroup
                        row
                        aria-label='layout'
                        value={filters?.booked_by ?? '0'}
                        onChange={e =>
                          setFilters(prev => {
                            const val = e.target.value
                            const newVal = {
                              ...prev,
                              booked_by: e.target.value
                            }
                            if (val == 0) {
                              delete newVal.booked_by
                            }
                            return newVal
                          })
                        }
                        name='row-radio-buttons-group'
                      >
                        <FormControlLabel
                          value='0'
                          control={<Radio />}
                          label={lang == 'Arabic' ? 'أيهم' : 'Any'}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            '& .MuiFormControlLabel-label': {
                              color: theme.palette.grey[900]
                            }
                          }}
                        />
                        <FormControlLabel
                          value='merchant'
                          control={<Radio />}
                          label={lang == 'Arabic' ? 'التاجر' : 'Merchant'}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            '& .MuiFormControlLabel-label': {
                              color: theme.palette.grey[900]
                            }
                          }}
                        />
                        <FormControlLabel
                          value='user'
                          control={<Radio />}
                          label={lang == 'Arabic' ? 'المستخدم' : 'User'}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            '& .MuiFormControlLabel-label': {
                              color: theme.palette.grey[900]
                            }
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  {report.type == 'bookings' && (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ pt: '0 !important' }}>
                        <Accordion
                          data={[
                            {
                              id: 'cancellation',
                              defaultExpand: false,
                              title:
                                lang == 'Arabic' ? 'الإلغاء' : 'Cancellation',
                              content: (
                                <Grid xs={12} sx={{ pt: '0px !important' }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={filters.canceled_by_null}
                                        value={filters.canceled_by_null}
                                        name='canceled_by_null'
                                        color='primary'
                                        onChange={() =>
                                          setFilters(prev => ({
                                            ...prev,
                                            canceled_by_null: !prev.canceled_by_null
                                          }))
                                        }
                                        className={'check'}
                                      />
                                    }
                                    label={
                                      lang == 'Arabic'
                                        ? 'الغير ملغي'
                                        : 'Not canceled'
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={filters.canceled_by_merchant}
                                        value={filters.canceled_by_merchant}
                                        name='canceled_by_merchant'
                                        color='primary'
                                        onChange={() =>
                                          setFilters(prev => ({
                                            ...prev,
                                            canceled_by_merchant: !prev.canceled_by_merchant
                                          }))
                                        }
                                        className={'check'}
                                      />
                                    }
                                    label={
                                      lang == 'Arabic'
                                        ? 'ملغي من قبل التاجر'
                                        : 'Canceled by merchant'
                                    }
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={filters.canceled_by_user}
                                        value={filters.canceled_by_user}
                                        name='canceled_by_user'
                                        color='primary'
                                        onChange={() =>
                                          setFilters(prev => ({
                                            ...prev,
                                            canceled_by_user: !prev.canceled_by_user
                                          }))
                                        }
                                        className={'check'}
                                      />
                                    }
                                    label={
                                      lang == 'Arabic'
                                        ? 'ملغي من قبل المستخدم'
                                        : 'Canceled by user'
                                    }
                                  />
                                </Grid>
                              )
                            }
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )
            }
          ]}
        />

        {report.type != 'bookings' && (
          <>
            <Accordion
              data={[
                {
                  id: 'booked_unit_level',
                  defaultExpand: false,
                  title: (
                    <Grid item xs={12} sx={{ mt: '0px' }}>
                      <Typography fontWeight={'bold'}>
                        {lang == 'Arabic'
                          ? `تصفية مستوى ${bookingSchemes.booked_unit_si_ar}`
                          : `${capitalize(
                              bookingSchemes.booked_unit_si
                            )} level filter`}
                      </Typography>
                    </Grid>
                  ),
                  content: (
                    <>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid
                          item
                          xs={12}
                          display='flex'
                          justifyContent={'space-between'}
                        >
                          <Typography fontWeight={'bold'}>
                            {lang == 'Arabic'
                              ? 'التاريخ المحجوز'
                              : 'Booked date'}
                          </Typography>
                          <CustomPeriod
                            startDate={filters.from_starting_date}
                            endDate={filters.to_starting_date}
                            onPeriodChange={filter => {
                              setFilters(prev => ({
                                ...prev,
                                from_starting_date: filter.start,
                                to_starting_date: filter.end
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: '15px' }}>
                          <CustomDatePicker
                            value={filters.from_starting_date}
                            minDate={null}
                            // maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ البدء' : 'Start date'
                            }
                            name='from_starting_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                from_starting_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            value={filters.to_starting_date}
                            minDate={null}
                            // maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ الانتهاء' : 'End date'
                            }
                            name='to_starting_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                to_starting_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: '0 !important' }}
                          display='flex'
                          justifyContent={'end'}
                        >
                          <Button
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                from_starting_date: null,
                                to_starting_date: null
                              }))
                            }
                          >
                            {lang == 'Arabic' ? 'مسح' : 'Clear'}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid
                          item
                          xs={12}
                          display='flex'
                          justifyContent={'space-between'}
                        >
                          <Typography fontWeight={'bold'}>
                            {lang == 'Arabic'
                              ? `تم إنشاء ${bookingSchemes.booked_unit_pl_ar} في`
                              : `${capitalize(
                                  bookingSchemes.booked_unit_pl
                                )} created on`}
                          </Typography>
                          <CustomPeriod
                            startDate={filters.from_booked_units_created_date}
                            endDate={filters.to_booked_units_created_date}
                            onPeriodChange={filter => {
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_created_date: filter.start,
                                to_booked_units_created_date: filter.end
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: '15px' }}>
                          <CustomDatePicker
                            value={filters.from_booked_units_created_date}
                            minDate={null}
                            maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ البدء' : 'Start date'
                            }
                            name='from_booked_units_created_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_created_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            value={filters.to_booked_units_created_date}
                            minDate={null}
                            maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ الانتهاء' : 'End date'
                            }
                            name='to_booked_units_created_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                to_booked_units_created_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: '0 !important' }}
                          display='flex'
                          justifyContent={'end'}
                        >
                          <Button
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_created_date: null,
                                to_booked_units_created_date: null
                              }))
                            }
                          >
                            {lang == 'Arabic' ? 'مسح' : 'Clear'}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid
                          item
                          xs={12}
                          display='flex'
                          justifyContent={'space-between'}
                        >
                          <Typography fontWeight={'bold'}>
                            {lang == 'Arabic'
                              ? `تم تحديث ${bookingSchemes.booked_unit_pl_ar} في`
                              : `${capitalize(
                                  bookingSchemes.booked_unit_pl
                                )} updated on`}
                          </Typography>
                          <CustomPeriod
                            startDate={filters.from_booked_units_updated_date}
                            endDate={filters.to_booked_units_updated_date}
                            onPeriodChange={filter => {
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_updated_date: filter.start,
                                to_booked_units_updated_date: filter.end
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: '15px' }}>
                          <CustomDatePicker
                            value={filters.from_booked_units_updated_date}
                            minDate={null}
                            maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ البدء' : 'Start date'
                            }
                            name='from_booked_units_updated_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_updated_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            value={filters.to_booked_units_updated_date}
                            minDate={null}
                            maxDate={new Date()}
                            title={
                              lang == 'Arabic' ? 'تاريخ الانتهاء' : 'End date'
                            }
                            name='to_booked_units_updated_date'
                            onChange={val => {
                              setFilters(prev => ({
                                ...prev,
                                to_booked_units_updated_date: val
                              }))
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: '0 !important' }}
                          display='flex'
                          justifyContent={'end'}
                        >
                          <Button
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                from_booked_units_updated_date: null,
                                to_booked_units_updated_date: null
                              }))
                            }
                          >
                            {lang == 'Arabic' ? 'مسح' : 'Clear'}
                          </Button>
                        </Grid>
                      </Grid>
                      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography fontWeight={'bold'}>
                            {capitalize(bookingSchemes.resource_si)} and{' '}
                            {bookingSchemes.service_si}
                          </Typography>
                          <Typography>(based on current names)</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            id='filter-grouped-resources'
                            options={resources
                              .map(res => {
                                const newres = res
                                if (!newres?.group && groupResources) {
                                  newres.group =
                                    'Other ' + bookingSchemes.resource_pl
                                }
                                return newres
                              })
                              .sort(
                                (a, b) => -b.group?.localeCompare(a?.group)
                              )}
                            groupBy={option => option.group}
                            getOptionLabel={option => option.name}
                            onChange={(e, value) => {
                              setFilters(prev => {
                                const val = value?.id
                                const newVal = {
                                  ...prev,
                                  booking_resource_id: val
                                }
                                if (val == 0 || !val) {
                                  delete newVal.booking_resource_id
                                }
                                return newVal
                              })
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={capitalize(bookingSchemes.resource_pl)}
                                value={filters.booking_resource_id ?? '0'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            id='filter-grouped-resources'
                            options={services
                              .map(res => {
                                const newres = res
                                if (!newres?.group && groupServices) {
                                  newres.group =
                                    'Other ' + bookingSchemes.service_pl
                                }
                                return newres
                              })
                              .sort(
                                (a, b) => -b.group?.localeCompare(a?.group)
                              )}
                            groupBy={option => option.group}
                            getOptionLabel={option => option.name}
                            onChange={(e, value) => {
                              setFilters(prev => {
                                const val = value?.id
                                const newVal = {
                                  ...prev,
                                  booking_service_id: val
                                }
                                if (val == 0 || !val) {
                                  delete newVal.booking_service_id
                                }
                                return newVal
                              })
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={capitalize(bookingSchemes.service_si)}
                                value={filters.booking_service_id ?? '0'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: '15px !important' }}
                          display='flex'
                          justifyContent={'end'}
                        >
                          <Button
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                booking_service_id: 0,
                                booking_resource_id: 0
                              }))
                            }
                          >
                            Clear
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography fontWeight={'bold'}>
                            {capitalize(bookingSchemes.resource_si)} and{' '}
                            {bookingSchemes.service_si}
                          </Typography>
                          <Typography>(based on names when booked)</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={
                              capitalize(bookingSchemes.resource_si) + ' name'
                            }
                            value={filters.booking_resource_name ?? ''}
                            onChange={e =>
                              setFilters(prev => {
                                const val = e.target.value
                                const newVal = {
                                  ...prev,
                                  booking_resource_name: val
                                }

                                return newVal
                              })
                            }
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={
                              capitalize(bookingSchemes.service_si) + ' name'
                            }
                            value={filters.booking_service_name ?? ''}
                            onChange={e =>
                              setFilters(prev => {
                                const val = e.target.value
                                const newVal = {
                                  ...prev,
                                  booking_service_name: val
                                }
                                if (val == '') {
                                  delete newVal.booking_service_name
                                }
                                return newVal
                              })
                            }
                          ></TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ pt: '15px !important' }}
                          display='flex'
                          justifyContent={'end'}
                        >
                          <Button
                            onClick={() =>
                              setFilters(prev => ({
                                ...prev,
                                booking_service_name: '',
                                booking_resource_name: ''
                              }))
                            }
                          >
                            Clear
                          </Button>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ pt: '0 !important' }}>
                        <Accordion
                          data={[
                            {
                              id: 'cancellation',
                              defaultExpand: false,
                              title:
                                lang == 'Arabic' ? 'الإلغاء' : 'Cancellation',
                              content: (
                                <Grid xs={12} sx={{ pt: '0px !important' }}>
                                  <RadioGroup
                                    row
                                    aria-label='layout'
                                    value={filters.canceled_by ?? '0'}
                                    onChange={e => {
                                      const val = e.target.value
                                      setFilters(prev => {
                                        const newVal = {
                                          ...prev,
                                          cancel_from: 'any',
                                          canceled_by: e.target.value
                                        }
                                        if (val == 0) {
                                          delete newVal.canceled_by
                                        }
                                        return newVal
                                      })
                                    }}
                                    name='row-radio-buttons-group'
                                  >
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        value='0'
                                        control={<Radio />}
                                        label={
                                          lang == 'Arabic'
                                            ? 'بدون تصفية'
                                            : 'No filter'
                                        }
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 28
                                          },
                                          '& .MuiFormControlLabel-label': {
                                            color: theme.palette.grey[900]
                                          }
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        value='null'
                                        control={<Radio />}
                                        label={
                                          lang == 'Arabic'
                                            ? 'بدون الإلغاءات'
                                            : 'No cancellations'
                                        }
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 28
                                          },
                                          '& .MuiFormControlLabel-label': {
                                            color: theme.palette.grey[900]
                                          }
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        value='has_one_cancellation'
                                        control={<Radio />}
                                        label={
                                          lang == 'Arabic'
                                            ? 'الإلغاءات فقط'
                                            : 'Only cancellations'
                                        }
                                        sx={{
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 28
                                          },
                                          '& .MuiFormControlLabel-label': {
                                            color: theme.palette.grey[900]
                                          }
                                        }}
                                      />
                                      {filters.canceled_by ==
                                        'has_one_cancellation' && (
                                        <Grid
                                          item
                                          xs={12}
                                          sx={{
                                            pt: '0 !important',
                                            pl: '20px'
                                          }}
                                        >
                                          <RadioGroup
                                            row
                                            aria-label='layout'
                                            value={filters.cancel_from ?? 'any'}
                                            onChange={e => {
                                              setFilters(prev => {
                                                const val = e.target.value
                                                const newVal = {
                                                  ...prev,
                                                  cancel_from: e.target.value
                                                }
                                                if (val == 0) {
                                                  delete newVal.cancel_from
                                                }
                                                return newVal
                                              })
                                            }}
                                            name='row-radio-buttons-group'
                                          >
                                            <FormControlLabel
                                              value='user'
                                              control={<Radio />}
                                              label={
                                                lang == 'Arabic'
                                                  ? 'من قبل المستخدم'
                                                  : 'by user'
                                              }
                                              sx={{
                                                '& .MuiSvgIcon-root': {
                                                  fontSize: 28
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                  color: theme.palette.grey[900]
                                                }
                                              }}
                                            />
                                            <FormControlLabel
                                              value='merchant'
                                              control={<Radio />}
                                              label={
                                                lang == 'Arabic'
                                                  ? 'من قبل التاجر'
                                                  : 'by merchant'
                                              }
                                              sx={{
                                                '& .MuiSvgIcon-root': {
                                                  fontSize: 28
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                  color: theme.palette.grey[900]
                                                }
                                              }}
                                            />

                                            <FormControlLabel
                                              value='any'
                                              control={<Radio />}
                                              label={
                                                lang == 'Arabic'
                                                  ? 'من قبل أيهم'
                                                  : 'by any'
                                              }
                                              sx={{
                                                '& .MuiSvgIcon-root': {
                                                  fontSize: 28
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                  color: theme.palette.grey[900]
                                                }
                                              }}
                                            />
                                          </RadioGroup>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </RadioGroup>
                                </Grid>
                              )
                            }
                          ]}
                        />
                      </Grid>
                    </>
                  )
                }
              ]}
            />
          </>
        )}
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider />
        </Grid>
        {!canGenerate && (
          <Grid>
            <FormHelperText error sx={{ textAlign: 'right' }}>
              {hasOneDate
                ? Object.values(errors).find(err => err)
                : lang == 'Arabic'
                ? 'مطلوب نطاق زمني واحد للتصفية على الأقل'
                : 'At least one filter date range is required'}
            </FormHelperText>
          </Grid>
        )}
        {report.type == 'custom' && (
          <Grid item xs={12} pt={'15px'}>
            <Grid item xs={12} md={6}>
              <CustomSelect
                label={lang == 'Arabic' ? 'تجميع حسب' : 'Group by'}
                options={groups}
                value={report?.groups ?? []}
                onChange={val => {
                  setReport(prev => ({ ...prev, groups: val }))
                }}
              />
              {report?.grouping_error && (
                <FormHelperText error>{report.grouping_error}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid
            item
            xs={12}
            sx={{ my: 2 }}
            display='flex'
            justifyContent={'end'}
          >
            {generated && (
              <Button
                variant='outlined'
                onClick={exportReport}
                sx={{ marginRight: '20px' }}
              >
                {lang == 'Arabic' ? 'تحميل التقرير' : 'DOWNLOAD REPORT'}
              </Button>
            )}
            <Button
              disabled={!canGenerate}
              variant='contained'
              style={
                user && user?.credit_balance < min_credit_bal
                  ? { cursor: 'not-allowed' }
                  : {}
              }
              // color='error'
              onClick={async () => {
                if (user && user?.credit_balance < min_credit_bal) return
                if (report.type == 'custom') {
                  if (report.groups.length < 1) {
                    setReport(prev => ({
                      ...prev,
                      grouping_error:
                        lang == 'Arabic'
                          ? 'مطلوب مجموعة واحدة على الأقل'
                          : 'At least one grouping is needed'
                    }))
                    return
                  }
                  setReport(prev => ({
                    ...prev,
                    grouping_error: null
                  }))
                }

                setloading(true)
                await getAllBookings()
                setGenerated(true)
                setloading(false)
              }}
            >
              {lang == 'Arabic' ? 'إنشاء التقرير' : 'GENERATE REPORT'}
            </Button>
          </Grid>
        </Grid>
      </MainCard>
      <Grid item xs={12}>
        {generated && (
          <SimpleTable
            onAdd={() => {}}
            onEdit={() => {}}
            onDelete={() => {}}
            plain
            rows={rows}
            columns={report.type == 'custom' ? customColumns : columns}
            title=''
          />
        )}
      </Grid>
    </MainCard>
  )
}
export default BookingReports
